// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-all-tsx": () => import("./../../../src/pages/blog/all.tsx" /* webpackChunkName: "component---src-pages-blog-all-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-ebook-tsx": () => import("./../../../src/pages/ebook.tsx" /* webpackChunkName: "component---src-pages-ebook-tsx" */),
  "component---src-pages-image-tsx": () => import("./../../../src/pages/image.tsx" /* webpackChunkName: "component---src-pages-image-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-obsah-zadarmo-tsx": () => import("./../../../src/pages/obsah-zadarmo.tsx" /* webpackChunkName: "component---src-pages-obsah-zadarmo-tsx" */),
  "component---src-pages-page-2-tsx": () => import("./../../../src/pages/page-2.tsx" /* webpackChunkName: "component---src-pages-page-2-tsx" */),
  "component---src-pages-preco-so-mnou-tsx": () => import("./../../../src/pages/preco-so-mnou.tsx" /* webpackChunkName: "component---src-pages-preco-so-mnou-tsx" */),
  "component---src-pages-sluzby-brief-koucing-tsx": () => import("./../../../src/pages/sluzby/brief-koucing.tsx" /* webpackChunkName: "component---src-pages-sluzby-brief-koucing-tsx" */),
  "component---src-pages-sluzby-brief-terapia-tsx": () => import("./../../../src/pages/sluzby/brief-terapia.tsx" /* webpackChunkName: "component---src-pages-sluzby-brief-terapia-tsx" */),
  "component---src-pages-sluzby-inspirativne-workshopy-tsx": () => import("./../../../src/pages/sluzby/inspirativne-workshopy.tsx" /* webpackChunkName: "component---src-pages-sluzby-inspirativne-workshopy-tsx" */),
  "component---src-pages-sluzby-liderske-a-obchodne-zrucnosti-tsx": () => import("./../../../src/pages/sluzby/liderske-a-obchodne-zrucnosti.tsx" /* webpackChunkName: "component---src-pages-sluzby-liderske-a-obchodne-zrucnosti-tsx" */),
  "component---src-pages-sluzby-tsx": () => import("./../../../src/pages/sluzby.tsx" /* webpackChunkName: "component---src-pages-sluzby-tsx" */),
  "component---src-templates-blog-template-tsx": () => import("./../../../src/templates/blogTemplate.tsx" /* webpackChunkName: "component---src-templates-blog-template-tsx" */)
}

